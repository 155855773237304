<template>
  <app-form-view
    app="role"
    model="role"
    api-url="role/role/"
    :title="$t('menu.department')"
    v-model="formData"
    :approveItems="approveItems"
    :state="formData.state"
    :stateItems="stateItems"
  >
    <template v-slot="{ view, mode }">
      <div class="mt-4">
        <v-row>
          <v-col cols="12" lg="6" class="app-input--approve-wrapper">
            <app-input
              name="name"
              rules="required"
              class="w-100"
              :label="$t('fields.departmentName')"
              :view="view"
              v-model="formData.name"
            />
            <app-input
              name="approveDepartmentName"
              type="switch"
              class="approve"
              :label="$t('fields.approve')"
              v-model="formData.approve_departmentName"
              :view="view"
              v-if="isSentToApprove"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" class="app-input--approve-wrapper">
            <app-input
              name="description"
              :label="$t('fields.description')"
              type="textarea"
              :view="view"
              v-model="formData.description"
            />
            <app-input
              name="approveDesciption"
              type="switch"
              class="approve"
              :label="$t('fields.approve')"
              :view="view"
              v-model="formData.approve_desciption"
              v-if="isSentToApprove"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <app-input
              name="active"
              type="switch"
              :label="$t('fields.active')"
              :view="view"
              v-model="formData.active"
            />
          </v-col>
        </v-row>

        <v-card v-if="mode !== 'create'">
          <v-tabs dark background-color="primary" v-model="tab">
            <v-tab>Standard Price</v-tab>
            <v-tab>Custom Price</v-tab>
            <v-tab>Required Document</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <app-table
                    server-side
                    ref="table"
                    app="role"
                    model="rolecustomprice"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :headers="headers"
                    :serverItems="standardPriceItems"
                    :clientItems="standardPriceItems"
                    :loading="loading"
                    :server-items-length="standardPriceItemsLength"
                    v-model="standardPriceSelected"
                    @delete="onDeleteItem"
                    @server="getStandardPriceItems"
                    @edit="onEdit"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>
                          {{ $t('label.standardPrice') }}
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>

                        <v-dialog
                          v-model="dialogStandardPrice"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <slot name="actions">
                              <v-btn
                                v-show="mode === 'edit'"
                                dark
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                New Item
                              </v-btn>
                              <v-btn
                                v-show="
                                  standardPriceSelected.length &&
                                  mode === 'edit'
                                "
                                color="error"
                                @click="onDelete(standardPriceSelected)"
                              >
                                {{ $t('btn.delete') }}
                              </v-btn>
                            </slot>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.start_date"
                                      name="startdate"
                                      :label="$t('fields.startDate')"
                                      type="datepicker"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.end_date"
                                      name="enddate"
                                      :label="$t('fields.endDate')"
                                      type="datepicker"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="
                                        editedItem.premium_percent_charge
                                      "
                                      name="role"
                                      label="premium charge %"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.premium_charge"
                                      name="premiumPercentCharge"
                                      label="premium charge"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.standard_charge"
                                      name="standard_charge"
                                      label="standardCharge"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.premium_hour_charge"
                                      name="premiumHourCharge"
                                      label="premiumHourCharge"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeStandardPrice"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="saveStandardPrice"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                  </app-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <app-table
                    server-side
                    ref="table"
                    app="role"
                    model="rolecustomprice"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :serverItems="customPriceItems"
                    :clientItems="customPriceItems"
                    :headers="headers"
                    :loading="loading"
                    :server-items-length="customPriceItemsLength"
                    v-model="selected"
                    @delete="onDeleteItem"
                    @server="getCustomPriceItems"
                    @edit="onEdit"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>
                          {{ $t('label.custom') }}
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>

                        <v-dialog v-model="dialog" max-width="500px">
                          <template v-slot:activator="{ on, attrs }">
                            <slot name="actions">
                              <slot name="prefix-actions" />
                              <v-btn
                                v-show="mode === 'edit'"
                                dark
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                New Item
                              </v-btn>
                              <v-btn
                                v-show="selected.length && mode === 'edit'"
                                color="error"
                                @click="onDelete(selected)"
                              >
                                {{ $t('btn.delete') }}
                              </v-btn>

                              <slot name="suffix-actions" />
                            </slot>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.start_date"
                                      name="startdate"
                                      label="startdate"
                                      type="datepicker"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.end_date"
                                      name="enddate"
                                      label="enddate"
                                      type="datepicker"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="
                                        editedItem.premium_percent_charge
                                      "
                                      name="role"
                                      label="premium charge %"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.premium_charge"
                                      name="premiumPercentCharge"
                                      label="premium charge"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.standard_charge"
                                      name="standard_charge"
                                      label="standardCharge"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      v-model="editedItem.premium_hour_charge"
                                      name="premiumHourCharge"
                                      label="premiumHourCharge"
                                      :view="view"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="close">
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="saveCustomPrice"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                  </app-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <template>
                    <app-table
                      server-side
                      ref="table"
                      app="role"
                      model="rolerequirement"
                      :hide-edit="mode !== 'edit'"
                      :hide-delete="mode !== 'edit'"
                      :serverItems="documentItems"
                      :clientItems="documentItems"
                      :headers="roleHeaders"
                      :loading="loading"
                      :server-items-length="serverDocumentItemsLength"
                      v-model="selectedDocument"
                      @delete="onDeleteDocumentItem"
                      @server="getdocumentItems"
                      @edit="onEditDocumentItem"
                    >
                      <template v-slot:top>
                        <div class="app-list-view">
                          <div class="app-list-view-header">
                            <v-toolbar flat>
                              <v-toolbar-title
                                >Role Requirement</v-toolbar-title
                              >
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              ></v-divider>
                              <v-spacer></v-spacer>

                              <v-dialog
                                v-model="dialogDocument"
                                max-width="500px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <slot name="actions">
                                    <slot name="prefix-actions" />
                                    <v-btn
                                      color="primary"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                      v-show="
                                        mode === 'edit' ||
                                        formData.state === 'draft'
                                      "
                                    >
                                      New Item
                                    </v-btn>
                                    <v-btn
                                      v-show="
                                        selectedDocument.length &&
                                        (mode === 'edit' ||
                                          formData.state === 'draft')
                                      "
                                      color="error"
                                      @click="
                                        onDeleteDocument(selectedDocument)
                                      "
                                    >
                                      {{ $t('btn.delete') }}
                                    </v-btn>

                                    <slot name="suffix-actions" />
                                  </slot>
                                </template>
                                <v-card>
                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col cols="4" sm="6" md="6">
                                          <app-input
                                            v-model="
                                              editedDocumentItem.document_template_id
                                            "
                                            name="document-template"
                                            type="select-server"
                                            :label="
                                              $t('fields.documentTemplate')
                                            "
                                            :view="view"
                                            :binds="{
                                              apiUrl:
                                                'doc-template/document/?active=true'
                                            }"
                                          >
                                          </app-input>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col>
                                          <app-table
                                            app="role"
                                            model="rolerequirement"
                                            :headers="conditionHeaders"
                                            :serverItems="conditionsItems"
                                            :clientItems="conditionsItems"
                                            v-bind="binds"
                                            v-model="selectedCondition"
                                            itemKey="field"
                                            :server-items-length="
                                              serverConditionItemsLength
                                            "
                                            @edit="onEditConditionItems"
                                            @delete="onDeleteConditionItem"
                                          >
                                            <template v-slot:top>
                                              <div class="app-list-view">
                                                <div
                                                  class="app-list-view-header"
                                                >
                                                  <v-toolbar flat>
                                                    <v-toolbar-title
                                                      >Conditions</v-toolbar-title
                                                    >
                                                    <v-divider
                                                      class="mx-4"
                                                      inset
                                                      vertical
                                                    ></v-divider>
                                                    <v-spacer></v-spacer>

                                                    <v-dialog
                                                      v-model="dialogConditions"
                                                      max-width="500px"
                                                    >
                                                      <template
                                                        v-slot:activator="{
                                                          on,
                                                          attrs
                                                        }"
                                                      >
                                                        <slot name="actions">
                                                          <slot
                                                            name="prefix-actions"
                                                          />
                                                          <v-btn
                                                            color="primary"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            v-show="
                                                              mode === 'edit' ||
                                                              formData.state ===
                                                                'draft'
                                                            "
                                                          >
                                                            New Item
                                                          </v-btn>
                                                          <v-btn
                                                            v-show="
                                                              selectedCondition.length &&
                                                              (mode ===
                                                                'edit' ||
                                                                formData.state ===
                                                                  'draft')
                                                            "
                                                            color="error"
                                                            @click="
                                                              onDeleteCondition(
                                                                selectedCondition
                                                              )
                                                            "
                                                          >
                                                            {{
                                                              $t('btn.delete')
                                                            }}
                                                          </v-btn>

                                                          <slot
                                                            name="suffix-actions"
                                                          />
                                                        </slot>
                                                      </template>
                                                      <v-card>
                                                        <v-card-text>
                                                          <v-container>
                                                            <v-row>
                                                              <v-col
                                                                cols="4"
                                                                sm="6"
                                                                md="6"
                                                              >
                                                                <app-input
                                                                  name="field"
                                                                  type="select"
                                                                  :label="
                                                                    $t(
                                                                      'fields.field'
                                                                    )
                                                                  "
                                                                  :items="
                                                                    fieldsItems
                                                                  "
                                                                  :view="view"
                                                                  v-model="
                                                                    editedConditionsItem.field
                                                                  "
                                                                >
                                                                </app-input>
                                                              </v-col>
                                                            </v-row>
                                                            <v-row>
                                                              <v-col>
                                                                <app-input
                                                                  name="operator"
                                                                  type="select"
                                                                  :label="
                                                                    $t(
                                                                      'fields.operator'
                                                                    )
                                                                  "
                                                                  :items="[
                                                                    {
                                                                      label:
                                                                        '>',
                                                                      value: '>'
                                                                    },
                                                                    {
                                                                      label:
                                                                        '<',
                                                                      value: '<'
                                                                    },
                                                                    {
                                                                      label:
                                                                        '=',
                                                                      value: '='
                                                                    },
                                                                    {
                                                                      label:
                                                                        '<=',
                                                                      value:
                                                                        '<='
                                                                    },
                                                                    {
                                                                      label:
                                                                        '>=',
                                                                      value:
                                                                        '>='
                                                                    },
                                                                    {
                                                                      label:
                                                                        '!=',
                                                                      value:
                                                                        '!='
                                                                    }
                                                                  ]"
                                                                  :view="view"
                                                                  v-model="
                                                                    editedConditionsItem.operator
                                                                  "
                                                                >
                                                                </app-input>
                                                              </v-col>
                                                            </v-row>
                                                            <v-row>
                                                              <v-col>
                                                                <app-input
                                                                  name="value"
                                                                  :label="
                                                                    $t(
                                                                      'fields.value'
                                                                    )
                                                                  "
                                                                  :view="view"
                                                                  v-model="
                                                                    editedConditionsItem.value
                                                                  "
                                                                >
                                                                </app-input>
                                                              </v-col>
                                                            </v-row>
                                                          </v-container>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                          <v-spacer></v-spacer>
                                                          <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="
                                                              closeCondition
                                                            "
                                                          >
                                                            Cancel
                                                          </v-btn>
                                                          <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="
                                                              saveCondition
                                                            "
                                                          >
                                                            Save
                                                          </v-btn>
                                                        </v-card-actions>
                                                      </v-card>
                                                    </v-dialog>
                                                  </v-toolbar>
                                                </div>
                                              </div>
                                            </template>
                                          </app-table>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeDocument"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="saveDocument"
                                    >
                                      Save
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-toolbar>
                          </div>
                        </div>
                      </template>
                    </app-table>
                  </template>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'

import AppFormView from '@components/AppFormView'
import AppTable from '@components/AppTable.vue'
export default {
  name: 'departmentForm',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  props: {
    binds: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    headers() {
      return [
        {
          text: this.$t('fields.startDate'),
          value: 'start_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.endDate'),
          value: 'end_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.charge'),
          value: 'standard_charge',
          hideFilter: true
        },
        {
          text: this.$t('fields.premiumChargeHour'),
          value: 'premium_hour_charge',
          hideFilter: true
        },
        {
          text: this.$t('fields.premiumChargePer'),
          value: 'premium_percent_charge',
          hideFilter: true
        },
        {
          text: this.$t('fields.premiumCharge'),
          value: 'premium_charge',
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    roleHeaders() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'document_template_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.conditions'),
          value: 'conditions',
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    conditionHeaders() {
      return [
        { text: this.$t('fields.field'), value: 'field', hideFilter: true },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          hideFilter: true
        },
        { text: this.$t('fields.value'), value: 'value', hideFilter: true },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  data() {
    return {
      tab: null,
      search: '',
      customPriceItems: [],
      documentItems: [],
      conditionsItems: [],
      savedItem: {},
      customPriceItemsLength: 0,
      serverDocumentItemsLength: 0,
      serverConditionItemsLength: 0,
      loading: false,
      selected: [],
      selectedDocument: [],
      selectedCondition: [],
      formData: {
        state: 'draft',
        active: true
      },
      approveItems: ['approve_departmentName', 'approve_desciption'],
      editedItem: {
        role_id: null,
        premium_percent_charge: null,
        premium_hour_charge: null,
        standard_charge: null
      },
      editedDocumentItem: {
        document_template_id: null,
        role_id: null,
        conditions: []
      },
      editedConditionsItem: {
        field: null,
        operator: null,
        value: null
      },
      fieldsItems: {
        code: null,
        field: null,
        name: null,
        field_type: null,
        selection_members: [],
        label: null,
        value: null
      },
      dialog: false,
      dialogDocument: false,
      dialogConditions: false,
      dialogDelete: false,
      apiMethod: 'post',
      editId: null,
      standardPriceItems: [],
      standardPriceItemsLength: 0,
      standardPriceSelected: [],
      dialogStandardPrice: false
    }
  },
  watch: {
    dialogDocument() {
      if (this.dialogDocument) {
        this.getConditionsItem()
      }
    },
    dialogConditions() {
      if (this.dialogConditions) {
        this.getFieldsItem()
      }
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialog = true
    },
    getdocumentItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `role/role-requirement?role_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.serverDocumentItemsLength = data.count
          this.documentItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getFieldsItem() {
      this.fieldsItems = []
      if (
        this.editedDocumentItem.document_template_id !== null &&
        this.editedDocumentItem.document_template_id !== undefined
      ) {
        this.editedDocumentItem.document_template_id.fields.forEach((value) => {
          value.label = value.name
          value.value = value.name
          this.fieldsItems.push(value)
        })
      }
    },
    getConditionsItem() {
      this.conditionsItems = []
      this.loading = true
      if (
        this.editedDocumentItem.conditions !== null &&
        this.editedDocumentItem.conditions !== undefined
      ) {
        this.serverConditionItemsLength =
          this.editedDocumentItem.conditions.length
        this.conditionsItems = this.editedDocumentItem.conditions
      }
      this.loading = false
    },
    getStandardPriceItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'role/role-custom-price/',
          params: {
            role_id: this.$route.params.id,
            price_type: 'standard'
          },
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.standardPriceItemsLength = data.count
          this.standardPriceItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getCustomPriceItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'role/role-custom-price/',
          params: {
            role_id: this.$route.params.id,
            price_type: 'custom'
          },
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.customPriceItemsLength = data.count
          this.customPriceItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    async saveStandardPrice() {
      this.loading = true
      const data = { price_type: 'standard' }
      data.role_id = this.$route.params.id

      if (
        this.editedItem.start_date !== null &&
        this.editedItem.start_date !== undefined
      ) {
        data.start_date = this.editedItem.start_date
      }
      if (
        this.editedItem.end_date !== null &&
        this.editedItem.end_date !== undefined
      ) {
        data.end_date = this.editedItem.end_date
      }
      if (
        this.editedItem.premium_charge !== null &&
        this.editedItem.premium_charge !== undefined
      ) {
        data.premium_charge = this.editedItem.premium_charge
      }
      if (
        this.editedItem.premium_hour_charge !== null &&
        this.editedItem.premium_hour_charge !== undefined
      ) {
        data.premium_hour_charge = this.editedItem.premium_hour_charge
      }
      if (
        this.editedItem.standard_charge !== null &&
        this.editedItem.standard_charge !== undefined
      ) {
        data.standard_charge = this.editedItem.standard_charge
      }
      if (
        this.editedItem.premium_percent_charge !== null &&
        this.editedItem.premium_percent_charge !== undefined
      ) {
        data.premium_percent_charge = this.editedItem.premium_percent_charge
      }
      await this.$api({
        method: this.apiMethod,
        url: `role/role-custom-price/${this.editId ? this.editId + '/' : ''}`,
        data
      })
      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeStandardPrice()
      this.getStandardPriceItems()
    },
    closeStandardPrice() {
      this.dialogStandardPrice = false
      this.editedItem = {}
    },
    async saveCustomPrice() {
      this.loading = true
      const data = { price_type: 'custom' }
      data.role_id = this.$route.params.id

      if (
        this.editedItem.start_date !== null &&
        this.editedItem.start_date !== undefined
      ) {
        data.start_date = this.editedItem.start_date
      }
      if (
        this.editedItem.end_date !== null &&
        this.editedItem.end_date !== undefined
      ) {
        data.end_date = this.editedItem.end_date
      }
      if (
        this.editedItem.premium_charge !== null &&
        this.editedItem.premium_charge !== undefined
      ) {
        data.premium_charge = this.editedItem.premium_charge
      }
      if (
        this.editedItem.premium_hour_charge !== null &&
        this.editedItem.premium_hour_charge !== undefined
      ) {
        data.premium_hour_charge = this.editedItem.premium_hour_charge
      }
      if (
        this.editedItem.standard_charge !== null &&
        this.editedItem.standard_charge !== undefined
      ) {
        data.standard_charge = this.editedItem.standard_charge
      }
      if (
        this.editedItem.premium_percent_charge !== null &&
        this.editedItem.premium_percent_charge !== undefined
      ) {
        data.premium_percent_charge = this.editedItem.premium_percent_charge
      }
      await this.$api({
        method: this.apiMethod,
        url: `role/role-custom-price/${this.editId ? this.editId + '/' : ''}`,
        data
      })
      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.close()
      this.getCustomPriceItems()
    },
    saveDocument() {
      this.loading = true
      if (this.editedDocumentIndex > -1) {
        Object.assign(
          this.documentItems[this.editedDocumentIndex],
          this.editedDocumentItem
        )
      } else {
        this.documentItems.push(this.editedDocumentItem)
      }
      this.loading = false
      this.closeDocument()
    },
    closeDocument() {
      this.dialogDocument = false
      this.$nextTick(() => {
        this.editedDocumentItem = Object.assign({}, this.defaultDocumentItem)
        this.editedDocumentIndex = -1
      })
    },
    saveCondition() {
      this.loading = true
      if (this.editedConditionsIndex > -1) {
        Object.assign(
          this.conditionsItems[this.editedConditionsIndex],
          this.editedConditionsItem
        )
      } else {
        this.conditionsItems.push(this.editedConditionsItem)
      }
      this.loading = false
      this.closeCondition()
    },
    closeCondition() {
      this.dialogConditions = false
      this.$nextTick(() => {
        this.editedConditionsItem = Object.assign(
          {},
          this.defaultConditionsItem
        )
        this.editedConditionsIndex = -1
      })
    },
    onEditConditionItems(item) {
      this.editedConditionsIndex = this.conditionsItems.indexOf(item)
      this.editedConditionsItem = Object.assign({}, item)
      this.dialogConditions = true
    },
    onDeleteConditionItem(item) {
      this.onDeleteCondition(item)
    },
    onDeleteCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        items.forEach((item) => {
          this.deleteSelectCondition(item)
        })
      })
      this.closeDeleteConditions()
      this.loading = false
    },
    deleteSelectCondition(item) {
      this.editedConditionsIndex = this.conditionsItems.indexOf(item)
      this.editedConditionsItem = Object.assign({}, item)
      this.conditionsItems.splice(this.editedConditionsIndex, 1)
    },
    closeDeleteConditions() {
      this.$nextTick(() => {
        this.editedConditionsItem = Object.assign(
          {},
          this.defaultConditionsItem
        )
        this.editedConditionsIndex = -1
      })
    },
    onDeleteDocumentItem(item) {
      this.onDeleteDocument(item)
    },
    onDeleteDocument(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        items.forEach((item) => {
          this.deleteSelectDocument(item)
        })
      })
      this.closeDeleteDocument()
      this.loading = false
    },
    deleteSelectDocument(item) {
      this.editedDocumentIndex = this.documentItems.indexOf(item)
      this.editedDocumentItem = Object.assign({}, item)
      this.documentItems.splice(this.editedDocumentIndex, 1)
    },
    closeDeleteDocument() {
      this.conditionsItems = []
      this.$nextTick(() => {
        this.editedDocumentItem = Object.assign({}, this.defaultDocumentItem)
        this.editedDocumentIndex = -1
      })
    },
    onEditDocumentItem(item) {
      this.editedDocumentIndex = this.documentItems.indexOf(item)
      this.editedDocumentItem = Object.assign({}, item)
      this.getdocumentItems()
      this.dialogDocument = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `role/role-custom-price/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    }
  }
}
</script>
